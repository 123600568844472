import React, { useEffect } from "react";
// import { Buffer } from "buffer";
import "../IndexPage/IndexPage.css";

function WinesakeEditPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [loading, setLoading] = useState(true);
  // const [winesake, setSugested] = useState([]);

  // useEffect(() => {
  //   fetch("https://suntaka-backend-production.up.railway.app/api/winesakepdf")
  //     .then(async (response) => {
  //       if (response.ok) {
  //         // Si la respuesta es válida, intentar procesarla como JSON
  //         const { data: buffer } = await response.json();
  //         setSugested(buffer.data);
  //       } else {
  //         // Si la respuesta no es válida, intentar procesarla como un archivo binario
  //         const buffer = await response.arrayBuffer();
  //         setSugested(buffer);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <div>
    <div className="sake-background"></div>
    <div className="card-container">
      <img
      className="pdf-sake"
      src="img/vinoabril2024.jpg"
      alt="Bodega 1">
      
      </img>
      <img
      className="pdf-sake"
      src="img/sakeabril2024.jpg"
      alt="Bodega 2">
      
      </img>
      </div>
      {/* {loading ? (
        <div className="loader">
          <span>Cargando...</span>
        </div>
      ) : (
        <div className="pdfReader" key={winesake.id}>
          <iframe
            title="Bodega"
            src={`data:application/pdf;base64,${Buffer.from(
              winesake,
              "binary"
            )}`}
            width="100%"
            height="200%"
            type="application/pdf"
            alt={`Imagen de la bodega`}
          />
          
        </div>
      )} */}
    </div>
  );
}

export default WinesakeEditPage;


