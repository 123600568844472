import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("https://suntaka-backend-production.up.railway.app/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Unable to log in");
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          navigate("/", { state: data });
        } else {
          // login failed - display an error message
          setError(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred");
      });
  };

  return (
    <div>
      <header className="admin">
        <h1 className="titleadmin">Inicio de sesión</h1>
      </header>

      <form className="admin" onSubmit={handleSubmit}>
        {error && <div>{error}</div>}
        <label className="labeladmin">
          Usuario: <br></br>
          <input
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </label>
        <br />
        <br></br>
        <label className="labeladmin">
          Contraseña:<br></br>
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </label>
        <br />
        <br />
        <button className="btn " type="submit">
          Iniciar sesión
        </button>
      </form>
    </div>
  );
}

export default LoginForm;

// import React, { useState} from 'react';
// import { Redirect } from 'react-router-dom';
// // import axios from 'axios';
// import "./login.css";

//         function LoginForm() {
//           const [username, setUsername] = useState('');
//           const [password, setPassword] = useState('');
//           const [error, setError] = useState('');

//           const handleSubmit = (event) => {
//             event.preventDefault();

//             fetch('http://localhost:3001/api/login', {
//               method: 'POST',
//               headers: {
//                 'Content-Type': 'application/json',
//               },
//               body: JSON.stringify({ username, password }),
//             })
//               .then((response) => {
//                 if (!response.ok) {
//                   throw new Error('Unable to log in');
//                 }
//                 return response.json();
//               })
//               .then((data) => {
//                 if (data.success) {
//                   render() {

//                       return <Redirect to="/" />;
//                     }

//                 } else {
//                   // login failed - display an error message
//                   setError(data.message);
//                 }
//               })
//               .catch((error) => {
//                 console.error(error);
//                 setError('An error occurred');
//               });
//           };

//           return (
//             <form onSubmit={handleSubmit}>
//               {error && <div className="error">{error}</div>}
//               <label>
//                 Username:
//                 <input
//                   type="text"
//                   value={username}
//                   onChange={(event) => setUsername(event.target.value)}
//                 />
//               </label>
//               <br />
//               <label>
//                 Password:
//                 <input
//                   type="password"
//                   value={password}
//                   onChange={(event) => setPassword(event.target.value)}
//                 />
//               </label>
//               <br />
//               <button type="submit">Log in</button>
//             </form>
//           );
//         }

//         export default LoginForm;

// return (
//   <div>
//     <ul>
//     <div className='navbar'>
//     <li className='item5'>
//       <a className='submi' href ='/'>Inicio</a>
//       </li>
//       </div>
//       </ul>
//       <br/>
//       <br/>
//       <br/>
//       <header className="container--index">
//       <h1 className="textoencima tit">
//         <strong>Inicio de sesión</strong>
//       </h1>
//     </header>
//       <br/>
//       <br/>
//   <form onSubmit={handleSubmit}>
//     <label htmlFor="username">Username:</label><br />
//     <input
//       type="text"
//       id="username"
//       name="username"
//       value={formData.username}
//       onChange={handleChange}
//     /><br />
//     <label htmlFor="password">Password:</label><br />
//     <input
//       type="password"
//       id="password"
//       name="password"
//       value={formData.password}
//       onChange={handleChange}
//     /><br /><br />

//     <input  className='subm item5' type="submit" value="Entrar" />
//   </form>
//   <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <br></br>
//     <footer className="section-footer">
//       <p>
//         copyright &copy; INFORLAN
//         <span id="date"></span>. all rights reserved
//       </p>
//     </footer>

//   </div>
// );
