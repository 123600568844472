import React, {  useEffect } from "react";
// import { Buffer } from "buffer";
import "../IndexPage/IndexPage.css";

function CardEditPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

//   const [loading, setLoading] = useState(true);
//   const [card, setSugested] = useState([]);

//   useEffect(() => {
//     fetch("https://suntaka-backend-production.up.railway.app/api/cardpdf")
//     .then(async (response) => {
//       if (response.ok) {
//         // Si la respuesta es válida, intentar procesarla como JSON
//         const { data: buffer } = await response.json();
//         setSugested(buffer.data);
//       } else {
//         // Si la respuesta no es válida, intentar procesarla como un archivo binario
//         const buffer = await response.arrayBuffer();
//         setSugested(buffer);
//       }
//       setLoading(false);
//     })
//     .catch((error) => {
//       console.error(error);
//       setLoading(false);
//     });
// }, []);


  return (
    <div>
    <div className="card-background"></div>
    <div className="card-container">
      <img
      className="pdf-card"
      src="img/cartaabril2024.jpg"
      alt="Carta 1">
      
      </img>
      <img
      className="pdf-card"
      src="img/cartaabril2024-2.jpg"
      alt="Carta 2">
      
      </img>
      </div>
      {/* {loading ? (
        <div className="loader">
          <span>Cargando...</span>
        </div>
      ) : (
        <div className="pdfReader" key={card.id}>
          <iframe
            title="Carta"
            src={`data:application/pdf;base64,${Buffer.from(
              card,
              "binary"
            )}`}
            width="100%"
            height="200%"
            type="application/pdf"
            alt={`Imagen de la carta`}
          />
        </div>
      )} */}
    </div>
  );
}

export default CardEditPage;
