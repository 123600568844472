import IndexPage from "./pages/IndexPage/IndexPage";
import AdminPage from "./pages/login/login";
import SugestedEditPage from "./pages/Edits/sugestedEdit/sugestedEdit";
import CardEditPage from "./pages/Edits/cardEdit/cardEdit";
import WinesakeEditPage from "./pages/Edits/winesakeEdit/winesakeEdit";
import DessertsEditPage from "./pages/Edits/dessertsEdit/dessertsEdit";
import SugestedPage from "./pages/Read/sugested";
import CardPage from "./pages/Read/card";
import WinesakePage from "./pages/Read/winesake";
import DessertsPage from "./pages/Read/desserts";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/sugestedEdit" element={<SugestedEditPage />} />
        <Route path="/cardEdit" element={<CardEditPage />} />
        <Route path="/winesakeEdit" element={<WinesakeEditPage />} />
        <Route path="/dessertsEdit" element={<DessertsEditPage />} />
        <Route path="/sugested" element={<SugestedPage />} />
        <Route path="/card" element={<CardPage />} />
        <Route path="/winesake" element={<WinesakePage />} />
        <Route path="/desserts" element={<DessertsPage />} />
        <Route path="/prueba" element={<div>PRUEBA!</div>} />
      </Routes>
    </div>
  );
}

export default App;
