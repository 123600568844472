import React, { useEffect } from "react";
// import { Buffer } from "buffer";
import "../IndexPage/IndexPage.css";

function SugestedEditPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [loading, setLoading] = useState(true);
  // const [sugested, setSugested] = useState([]);

  // useEffect(() => {
  //   fetch("https://suntaka-backend-production.up.railway.app/api/sugestedpdf")
  //     .then(async (response) => {
  //       if (response.ok) {
  //         // Si la respuesta es válida, intentar procesarla como JSON
  //         const { data: buffer } = await response.json();
  //         setSugested(buffer.data);
  //       } else {
  //         // Si la respuesta no es válida, intentar procesarla como un archivo binario
  //         const buffer = await response.arrayBuffer();
  //         setSugested(buffer);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <div>
    <div className="sugested-background"></div>
    <div className="card-container">
      <img
      className="pdf-sugested"
      src="img/sugerenciasjulio2024.jpeg"
      alt="Sugerencias">
      
      </img>
</div>
      {/* {loading ? (
        <div className="loader">
          <span>Cargando...</span>
        </div>
      ) : (
        <div className="pdfReader" key={sugested.id}>
          <iframe
            title="Sugerencias"
            src={`data:application/pdf;base64,${Buffer.from(
              sugested,
              "binary"
            )}`}
            width="100%"
            height="200%"
            type="application/pdf"
            alt={`Imagen de sugerencias`}
          />
        </div>
      )} */}
    </div>
  );
}

export default SugestedEditPage;


