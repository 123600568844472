
import React, { useState, useEffect } from 'react';
import {  useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';


function CardEditPage() {
  
  const  {state = { success: false }}  = useLocation();

  const navigate = useNavigate();
  
  function handleLogout(event) {
    event.preventDefault();
    // Borra la información de sesión del usuario
    localStorage.removeItem("state");
    // Redirige a la página de inicio
    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [image, setImage] = useState(null);


  const handleChange = (event) => {
    console.log(event.target.files)
    setImage(event.target.files[0]);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('image', image);

    axios.post('https://suntaka-backend-production.up.railway.app/api/card', formData)
    .then(() => {
  navigate("/", { state });
  console.log('Image saved to database');
}).catch((error) => {
  console.error(error);
});
  }


  return (
    <div>

<ul className="admin">
        <li>
                <p className='labeladmin'>ADMIN: {state.user.usuario}</p>
              </li>
              <li>
                <a className='labeladmin' href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
              </ul>


              <form onSubmit={handleSubmit}>
      <input className='labeladmin btn' type="file" onChange={handleChange} />
      <button className="btn" type="submit">Subir</button>
    </form>
    </div>
  );
}

export default CardEditPage;
